import React, { useState } from "react";
import { useEffect } from "react";
import { Menu, MenuProvider } from "./menu-provider";

export type MenuContextType = {
    menus: Menu[]
    activeIndex: number;
    onClickMenu: (index: number) => void
}

export const MenuContext = React.createContext<MenuContextType | null>(null);

export const MenuContextProvider: React.FC<React.ReactNode> = ({children}) => {

    const [menus, setMenus] = useState<Menu[]>([]);
    const [activeIndex, setActiveIndex] = useState<number>(0)

    useEffect(() => {
        const menuProvider: MenuProvider = new MenuProvider();
        setMenus(menuProvider.populateMenu());
    }, [])

    const onClickMenu = (index: number) => {
        setActiveIndex(index);
    }
    
    return(
        <MenuContext.Provider value={{
            menus,
            activeIndex,
            onClickMenu
        }}>
        {children}
        </MenuContext.Provider>
    )
}