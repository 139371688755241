import { useContext } from 'react';
import { MdHome, MdPerson, MdExplore, MdBuild, MdWork, MdGroup, MdBook } from 'react-icons/md'
import { MenuContext, MenuContextType } from '../../context/menu/menu-context';
import { Icon } from '../../context/menu/menu-provider';
import './sidebar.css'

function Sidebar() {

    const { menus, activeIndex, onClickMenu} = useContext(MenuContext) as MenuContextType;

    const renderIcon = (icon: Icon) => {
        switch (icon) {
            case Icon.HOME:
                return <MdHome size={18} className="icon"/>;
            case Icon.ABOUT:
                return <MdPerson size={18} className="icon"/>;
            case Icon.EXPERIENCE:
                return <MdExplore size={18} className="icon"/>;
            case Icon.SERVICES:
                return <MdBuild size={18} className="icon"/>;
            case Icon.PORTFOLIO:
                return <MdWork size={18} className="icon"/>;
            case Icon.CLIENTS:
                return <MdGroup size={18} className="icon"/>;
            case Icon.BLOG:
                return <MdBook size={18} className="icon"/>;
            default:
                return <MdHome size={18} className="icon"/>;
        }
    }

    return(
        <div className="left-sidebar">
            <div className="container">
                <div className="header-content">
                    <img
                        className='profile-picture'
                        src={'/assets/photo_profile_526.jpeg'}
                        alt='profile'
                    />
                    <span className="name">
                        Ghazi Fadil
                    </span>
                    <span className="skill">
                        I'm Fullstack Developer
                    </span>
                </div>

                <div className="menu">
                    {
                        menus.map((item, index) => {
                            return(
                                <div 
                                    className={index === activeIndex ? 'menu-item active' : 'menu-item'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onClickMenu(index);
                                    }}>
                                    {renderIcon(item.code)}
                                    <span>{item.name}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}



export default Sidebar;