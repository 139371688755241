import './App.css';
import Sidebar from './components/sidebar/Sidebar';
import Home from './components/home/Home';
import {MenuContextProvider} from './context/menu/menu-context';

function App() {
  return (
    <MenuContextProvider>
      <div className="App">
        {/* Sidebar */}
        <Sidebar/>


        <div className="rigth-side">
          <Home/>
        </div>
      </div>
    </MenuContextProvider>
  );
}

export default App;

