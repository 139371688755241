export interface Menu {
    name: string;
    code: Icon
}

export enum Icon {
    HOME,
    ABOUT,
    EXPERIENCE,
    SERVICES,
    PORTFOLIO,
    CLIENTS,
    BLOG
}

export class MenuProvider {

    populateMenu = (): Menu[] => {
        const menus: Menu[] = [
            {
                name: "Home",
                code: Icon.HOME
            },
            {
                name: "About",
                code: Icon.ABOUT
            },
            {
                name: "Experience",
                code: Icon.EXPERIENCE
            },
            {
                name: "Services",
                code: Icon.SERVICES
            },
            {
                name: "Portfolio",
                code: Icon.PORTFOLIO
            },
            {
                name: "Clients",
                code: Icon.CLIENTS
            },
            {
                name: "Blog",
                code: Icon.BLOG
            },
        ];

        return menus;
    }

}

