import './home.css';

function Home() {
    return(
        <>
            <img
                src={'/assets/background_compress.jpeg'}
                className='backdrop-image'
                alt="background"
            />
            <div
                className='backdrop-overlay'
            />
            <span className="name-font name">
                Hello, my name is 
                <strong> Ghazi Fadil Ramadhan</strong>
            </span>
            <span className="name-font skill">
                I'm a Fullstack Developer
            </span>
            <span className="name-font description">
                I'm a Fullstack Developer with extensive experience for over 5 years. My expertise is to create Backend API, Frontend Web, and Mobile Apps.
            </span>
        </>
    );
}

export default Home;